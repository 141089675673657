import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { graphql, Link, useStaticQuery } from "gatsby";

const Header = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulHeader {
        logo {
          file {
            url
          }
        }
        menu {
          file {
            url
          }
        }
        linkLabel1
        linkUrl1
        linkLabel2
        linkUrl2
        linkLabel3
        linkUrl3
        linkLabel4
        linkUrl4
        linkLabel5
        linkUrl5
        linkLabel6
        linkUrl6
      }
    }
  `);
  const {
    logo,
    menu,
    linkLabel1,
    linkUrl1,
    linkLabel2,
    linkUrl2,
    linkLabel3,
    linkUrl3,
    linkLabel4,
    linkUrl4,
    linkLabel5,
    linkUrl5,
    linkLabel6,
    linkUrl6,
  } = data.contentfulHeader;

  const dropdownRef = useRef(null);
  const dropDown = useRef();
  const [isActive, setIsActive] = useState(false);
  const onClickMenu = () => setIsActive(!isActive);
const [isDesktop, setIsDesktop] = useState(false)
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDown.current && !dropDown.current.contains(event.target)) {
        setIsActive(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropDown]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 752) {
        setIsDesktop(false);
      } else {
        setIsDesktop(true);
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="header-wrapper">
      <header>
        <div className="container">
          <div className="header-block">
            <input
              id="mobile_trigger"
              name="trigger"
              type="radio"
              className="d-none"
            />
            <label
              htmlFor="mobile_trigger"
              className="d-none mobile-menu-trigger"
            >
              <img src={menu.file.url} alt="Menu Ic" />
            </label>
            <div className="logo-hold">
              <Link to="/">
                <img src={logo.file.url} alt="logo" />
              </Link>
            </div>
            <div className="main-nav-block">
              <input
                id="mobile_trigger_close"
                name="trigger"
                type="radio"
                className="d-none"
              />
              <label
                htmlFor="mobile_trigger_close"
                className="d-none mobile-menu-trigger"
              >
                <span>&#9587;</span>
              </label>
              <div className="menu-nav">
                <ul>
                  {isDesktop ?<li>
                    <a
                      ref={dropDown}
                      onClick={onClickMenu}
                      href={linkUrl4}
                      className="aboutDropDownWrapper"
                      style={{ textDecoration: "none", paddingRight: "35px" }}
                    >
                      What We Treat
                      <span className="dropdownMenuArrow"></span>
                      <li
                        ref={dropdownRef}
                        className={`dropdownMenu ${
                          isActive ? "active" : "inactive"
                        }`}
                      >
                        <Link
                          to="/erectile-dysfunction"
                          style={{ textDecoration: "none" }}
                        >
                          <span>Erectile Dysfunction</span>
                        </Link>

                        <Link
                          to="/hair-health"
                          style={{ textDecoration: "none" }}
                        >
                          <span>Hair Loss</span>
                        </Link>
                      </li>
                    </a>
                  </li>:
                  <li className="mobileLi">
                    <Link to={linkUrl4}>{linkLabel4}</Link>
                  </li>}
                  <li>
                    <Link to={linkUrl1}>{linkLabel1}</Link>
                  </li>
                  <li>
                    <Link to={linkUrl2}>{linkLabel2}</Link>
                  </li>
                  <li>
                    <Link to={linkUrl3}>{linkLabel3}</Link>
                  </li>
                  
                </ul>
              </div>
              <div className="login-btn mobile-login-btn">
                <Link to={linkUrl5}>{linkLabel5}</Link>
              </div>
            </div>
            <div className="get-start-block">
              <div className="login-btn">
                <Link to={linkUrl5}>{linkLabel5}</Link>
              </div>
              <div className="get-start-btn">
                <Link to={linkUrl6} className="common-btn">
                  {linkLabel6}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Header;
