import React, { useState } from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import addToMailchimp from "gatsby-plugin-mailchimp";

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      contentfulFooterCtaBar {
        logo {
          file {
            url
          }
        }
        links {
          id
          title
          links {
            id
            label
            url
          }
        }
        instagram
        facebook
        twitter
        linkedin
      }
    }
  `);
  const {
    logo,
    links,
    instagram,
    facebook,
    twitter,
    linkedin,
  } = data.contentfulFooterCtaBar;
  const legal = links.filter((link) => link.title === "Legal")[0];
  const query = links.filter((link) => link.title === "Have a Question?")[0];
  const company = links.filter((link) => link.title === "Company")[0];
  const treatments = links.filter((link) => link.title === "Treatments")[0];

  const [email, setEmail] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    if (email === "") {
      setError("Type in your email");
      return;
    }
    if (!validateEmail(email)) {
      setError("Type in your actual email, we won't bite.");
      return;
    }

    const res = await addToMailchimp(email);
    if (res.result === "success") {
      setSuccess(true);
      setEmail("");
      setError("");
    }
  };

  return (
    <div className="footer-wrapper">
      <div className="container">
        <div className="footer-block">
          <div className="footer-col f-col-1">
            <div className="footer-logo">
              <Link to="/">
                <img src={logo.file.url} alt="Logo" />
              </Link>
              <div className="subscribeFooter">
                <div className="footer-common-title mobileOnly">
                  <h3>Subscribe for Updates</h3>
                  {!success ? (
                    <>
                      <input
                        placeholder="Your Email"
                        className="mailchimpForm"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <button onClick={handleSubmit}>
                        {" "}
                        <span style={{ marginRight: "5px", color: "#eb4b4b" }}>
                          Submit
                        </span>{" "}
                        <svg
                          id="right-arrow"
                          xmlns="http://www.w3.org/2000/svg"
                          width="16.06"
                          height="7.369"
                          viewBox="0 0 16.06 7.369"
                        >
                          <path
                            id="right-arrow-2"
                            data-name="right-arrow"
                            d="M11.254,12.6H.43a.417.417,0,0,0-.43.4V14.88a.417.417,0,0,0,.43.4H11.254v1.541a.869.869,0,0,0,1.469.568l3.085-2.88a.766.766,0,0,0,0-1.136l-3.085-2.88a.869.869,0,0,0-1.469.568Z"
                            transform="translate(0 -10.258)"
                            fill="#eb4b4b"
                          />
                        </svg>
                      </button>
                      <hr />
                    </>
                  ) : (
                    <p className="validation-success">
                      Thank you! We sent a sweet deal to your inbox
                    </p>
                  )}
                  {error && <p className="validation-error">{error}</p>}
                  <ul className="socialFooterIcons">
                    <li>
                      <a href={instagram}>
                        <i className="fa fa-instagram"></i>
                      </a>
                    </li>
                    <li>
                      <a href={facebook}>
                        <i className="fa fa-facebook-f"></i>
                      </a>
                    </li>
                    <li>
                      <a href={twitter}>
                        <i className="fa fa-twitter"></i>
                      </a>
                    </li>
                    <li>
                      <a href={linkedin}>
                        <i className="fa fa-linkedin"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {query.links.map((link) => (
                <div key={link.id}>
                  <div className="contact-block first desktopOnly">
                    <p>{link.label}</p>
                    <p className="lead">
                      <a href="#">{link.url}</a>
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="footer-col f-col-2">
            <div className="footer-box">
              <input type="checkbox" id="footer_drop" />
              <div className="footer-common-title">
                <h3>
                  <label htmlFor="footer_drop">{company.title}</label>
                </h3>
              </div>

              <ul className="footerUl">
                {company.links.map((link) => (
                  <li key={link.id}>
                    <Link to={link.url}>{link.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="footer-col f-col-3">
            <div className="footer-box">
              <input type="checkbox" id="footer_drop1" />
              <div className="footer-common-title">
                <h3>
                  <label htmlFor="footer_drop1">{treatments.title}</label>
                </h3>
              </div>
              <ul className="footerUl">
                {treatments.links?.map((el) => (
                  <li key={el.id}>
                    <Link to={el.url}>{el.label}</Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {query.links.map((link) => (
            <div key={link.id}>
              <div className="contact-block first mobileOnly">
                <p>{link.label}</p>
                <p className="lead">
                  <a href="#">{link.url}</a>
                </p>
              </div>
            </div>
          ))}
          <div className="footer-col f-col-6">
            <div className="subscribeFooter">
              <div className="footer-common-title desktopOnly">
                <h3>Subscribe for Updates</h3>
                {!success ? (
                  <>
                    <input
                      placeholder="Your Email"
                      className="mailchimpForm"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button onClick={handleSubmit}>
                      {" "}
                      <span style={{ marginRight: "5px", color: "#eb4b4b" }}>
                        Submit
                      </span>{" "}
                      <svg
                        id="right-arrow"
                        xmlns="http://www.w3.org/2000/svg"
                        width="16.06"
                        height="7.369"
                        viewBox="0 0 16.06 7.369"
                      >
                        <path
                          id="right-arrow-2"
                          data-name="right-arrow"
                          d="M11.254,12.6H.43a.417.417,0,0,0-.43.4V14.88a.417.417,0,0,0,.43.4H11.254v1.541a.869.869,0,0,0,1.469.568l3.085-2.88a.766.766,0,0,0,0-1.136l-3.085-2.88a.869.869,0,0,0-1.469.568Z"
                          transform="translate(0 -10.258)"
                          fill="#eb4b4b"
                        />
                      </svg>
                    </button>
                    <hr />
                  </>
                ) : (
                  <p className="validation-success">
                    Thank you! We sent a sweet deal to your inbox
                  </p>
                )}
                {error && <p className="validation-error">{error}</p>}
                <ul className="socialFooterIcons">
                  <li>
                    <a href={instagram}>
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href={facebook}>
                      <i className="fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href={twitter}>
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href={linkedin}>
                      <i className="fa fa-linkedin"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="footerPolicyLinks mobileOnly">
          {legal.links.map((link) => (
            <li key={link.id}>
              <Link to={link.url}>{link.label}</Link>
            </li>
          ))}
        </div>
        <div className="copyright-block">
          <p>© 2021 Brigo, All Rights Reserved</p>
          <div className="footerPolicyLinks desktopOnly">
            {legal.links.map((link) => (
              <li key={link.id}>
                <Link to={link.url}>{link.label}</Link>
              </li>
            ))}
          </div>
          <p>
            Powered by{" "}
            <a target="_blank" href="https://liftedsolutions.com/">
              Lifted Solutions
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
