import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

import Header from "./header"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>Brigo Health | A Men's HealthCare Platform</title>

        <meta charset="utf-8" />
        <meta name="description" content="Brigo Health | A Men's HealthCare Platform" />
        <meta name="author" content="Anshul Sharma of Lifted Solutions Inc." />
        <meta name="keywords" content="" />
        <meta property="og:title" content="Brigo Health | A Men's HealthCare Platform" />
        <meta property="og:type" content="" />
        <meta property="og:url" content="" />
        <meta property="og:image" content="" />
                
        <link
          rel="stylesheet"
          href="/assets/css/font-awesome.min.css"
          type="text/css"
        />
        <link rel="stylesheet" href="https://use.typekit.net/atx6nod.css" />
        <link type="text/css" rel="stylesheet" href="/assets/css/style.css" />
        <link
          type="text/css"
          rel="stylesheet"
          href="/assets/css/responsive.css"
        />
      </Helmet>
      <div className="main-wrapper">
        <Header />
        <main>{children}</main>
        <Footer />
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

